@import "../../../../assets/styles/vars";
@import "../../../../assets/styles/shared-styles";

.DateRow {
  &.ActionButton {
    width: 100%;
    overflow: hidden;
  }

  &.ActionRow {
    display: flex;
    align-content: center;
    text-align: center;

    column-gap: 10px;

    &.Date {
      display: flex;
      flex-direction: column;
      width: 30px;

      &.Weekday {
        font-size: 12px;
        margin-bottom: 6px;
      }
      &.Monthdate {
        font-size: 20px;
      }
    }

    &.RiderCount {
      grid-template-columns: 50% 50%;
      border-radius: 18.5px;
      border: solid 1px #c8c8c8;
      min-width: 50px;
      max-height: 31px;
      padding: 6px 2px 6px 2px;
      display: flex;
      justify-content: center;
      font-size: 14px;

      &.Overbooked {
        border: solid 1px rgba(255, 0, 0, 0.33);
        color: #ff0000;
      }
    }
  }
  &.info {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  &.firstColumn {
    display: flex;
    height: 60%;
    justify-content: start;
    column-gap: 25px;
    align-items: center;
    width: 23%;
    margin-left: 5px;

    @media screen and (max-width: 576px) {
      column-gap: 8px;
    }
  }

  &.secondColumn {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 63%;
  }

  &.lastColumn {
    display: flex;
    align-items: center;
    min-width: 30px;
  }

  &.past {
    opacity: 0.5;
  }
}
