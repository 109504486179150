@import "../../../../assets/styles/vars";
@import "../../../../assets/styles/shared-styles";

.ManageModalHeader {
  margin-right: 32px;
  margin-left: 20px;
  margin-top: 22px;
  margin-bottom: 22px;

  .Title {
    color: #0076ff;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .HeaderDate {
    max-height: 44px;
    margin-bottom: -30px;
    margin-left: 5px;
  }
}

.ManageModalBody {
  .Separator {
    height: 2px;
    border-top: solid 0.5px #c9d1d7;
    opacity: 0.5;
  }

  .Search {
    padding-left: 3px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .MemberList {
    min-height: 320px;
    max-height: 320px;
    overflow: auto;
    padding-top: 21px;
    padding-bottom: 21px;

    .Member {
      text-align: left;
    }
  }
}

.ManageModalFooter {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 16.5px;
  margin-right: 16.5px;

  .ButtonStack {
    display: grid;
    row-gap: 12px;
  }
}
