@import "../../assets/styles/vars";
@import "../../assets/styles/shared-styles";

.Submit {
  position: relative;
  display: block;
  width: 100%;
  margin: 2rem 0;
  padding: 0.75rem 1.5rem;
  background-color: $PRIMARY;
  border: none;
  border-radius: 0.25rem;
  line-height: 1.5rem;
  font-size: 1.125rem;
  color: $WHITE;
  text-transform: uppercase;
}

.Button {
  position: relative;
  display: block;
  width: 100%;
  margin: 2rem 0;
  padding: 0.75rem 1.5rem;
  background-color: $WHITE;
  border: solid;
  border-width: 2px;
  border-radius: 0.25rem;
  border-color: $PRIMARY;
  line-height: 1.5rem;
  font-size: 1.125rem;
  color: $PRIMARY;
  text-transform: uppercase;
}

.Button:disabled {
  border-color: $WHITE;
  color: $WHITE;
}

.Loader {
  position: absolute;
  right: 1.5rem;
  display: inline-block;
  height: 16px;
  width: 1px;
  margin-left: 16px;
  border-top: 2px solid #fff;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  animation: rotating 2s linear infinite;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
