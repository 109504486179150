@import "../../../../assets/styles/vars";
@import "../../../../assets/styles/shared-styles";

.DateRowSummary {
  padding: 10px 10px 10px 20px;

  &_date {
    margin-top: 10px;
    font-size: 20px;
  }

  &_timeline {
    font-size: 13px;
    margin-bottom: 20px;
  }
  &_message {
    font-size: 14px;
    margin-bottom: 10px;
    display: flex;
  }
  &_driveIcon {
    margin-left: 10px;
  }
  &_tickMark {
    margin-right: 5px;
    align-items: center;
    display: flex;
  }
}
