@import "../../../assets/styles/vars";
@import "../../../assets/styles/shared-styles";

.Divider {
  width: 100%;
  margin: 15px 5px 15px 5px;
  opacity: 0.5;
  border: solid 1px #c9d1d7;

  @media screen and (max-width: 576px) {
    margin: 15px 5px 15px 5px;
  }
}

.ButtonRow {
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 12px;
}

.ModalText {
  padding-bottom: 10px;
}

.MyPoolDates {
  &_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 0 5px 0;

    &_chooseDates {
      padding: 0;
      color: $BLUE;
      font-size: 19px;
      margin-bottom: 10px;
    }

    &_month {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }
}
