@import "../../../assets/styles/vars";

.Avatar {
  &-user {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    &-icon {
      flex: 0 0 40px;
      width: 40px;
      height: 40px;
      max-width: initial !important;
      max-height: initial !important;
      border-radius: 50%;
      background-color: red;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $WHITE;
      background-color: $GREY_300;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
