@import "../../../assets/styles/vars";

.Header {
  position: sticky;
  padding: 0 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  background-color: $GREY_100;
  position: sticky;
  top: 0;
  background-color: $WHITE;

  &-logo {
    height: 2em;
    margin: 0.5em 1em;

    &-image {
      max-height: 100%;
    }
  }

  &-nav {
    display: flex;
    align-items: center;

    &-item {
      display: inline-block;
      padding: 0.5em 1em;
      margin: 0 0.5em;
      white-space: nowrap;
      color: $ORANGE;
      cursor: pointer;
    }

    &-icon {
      display: none;

      &-close {
        display: none;
      }
    }

    &-backdrop {
      display: none;
    }
  }

  #nav-controller {
    position: fixed;
    left: -100%;
    top: 0;
  }

  &-user {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    &-icon {
      flex: 0 0 40px;
      width: 40px;
      height: 40px;
      max-width: initial !important;
      max-height: initial !important;
      border-radius: 50%;
      margin: 0 1em;
      background-color: red;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $WHITE;
      background-color: $GREY_300;
      font-weight: bold;
      text-transform: uppercase;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: baseline;
    }
  }

  .UserOptions {
    position: absolute;
    right: 0;
    top: 100%;
    transform: translate(0%, 10%);
    z-index: 9;
    background-color: $WHITE;
    width: 100%;
    box-shadow: -1px 2px 5px 0 rgba(0, 0, 0, 0.3);
    padding: 1em;
    min-width: 150px;

    &-logout {
      width: 100%;
      padding: 0.5em 1em;
    }
  }

  @media screen and (max-width: 959px) {
    position: relative;

    &-nav {
      overflow: hidden;
      padding-top: 4em;
      flex-direction: column-reverse;
      justify-content: flex-end;
      background-color: $WHITE;
      position: fixed;
      width: 30vh;
      height: 100vh;
      top: 0;
      left: 100%;
      z-index: 100000;
      transition: transform 0.3s ease;

      &-item {
        display: block;
      }

      &-icon {
        display: block;
        width: 40px;
        height: 40px;
        cursor: pointer;

        &-close {
          cursor: pointer;
          display: block;
          width: 30px;
          height: 30px;
          position: absolute;
          top: 1em;
          right: 1em;
        }
      }
    }

    .Header-nav-mobile-visible {
      transform: translate(-100%, 0);

      &-backdrop {
        display: initial;
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.62);
        z-index: 1;
      }
    }
  }
}
