@import "../../../../assets/styles/vars";

.InputValidator {
  text-align: left;

  &-Label {
    font-size: 0.75rem;
    color: $GREY_700;
  }

  &-Field {
    width: 100%;
    display: block;
  }

  &-Invalid {
    padding: 0 0.5rem;
    font-size: 0.7rem;
    color: $RED;
  }
}
