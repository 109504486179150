@import "../../../assets/styles/vars";
@import "../../../assets/styles/shared-styles";

.MyPoolDetails {
  text-align: center;
  margin: 2rem auto;
  margin-top: 0;
  max-width: 710px;

  .Divider {
    width: 100%;
    margin: 0;
  }

  .CalendarHeader {
    text-align: center;
    font-size: 19px;
    margin: 18px 25px 10px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .MonthNavigator {
      width: 34px;
      height: 34px;

      .SelectorArrow {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: solid 1px #c9d1d7;
        cursor: pointer;
      }
    }
  }

  color: #4a4a4a;

  .ButtonContainer {
    display: grid;
    row-gap: 15px;
    margin: 0px 25px;
  }
}

.NextTrip {
  margin-bottom: 50px;

  &.Date {
    font-size: 19px;
    margin-bottom: 25px;
  }

  &.Stops {
    display: flex;
    margin-top: -20px;
    margin-bottom: 10px;
    justify-content: center;
  }

  &.Driving {
    background-color: #032539;
    font-size: 14px;
    margin: -5px auto 25px auto;
    padding: 7px 12px 25px 12px;
    border-radius: 18.5px;
    border: solid 1px #c8c8c8;
    max-height: 31px;
    font-weight: 500;
    color: white;
    max-width: fit-content;
  }
}

.Weekdays {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  row-gap: 0px;
}

.WeekdayLabel {
  justify-items: center;
  font-size: 12px;
  color: #222;
  margin-bottom: 5px;
}

.NextRide {
  font-size: 12px;
}
