@import "../../../assets/styles/vars";
@import "../../../assets/styles/shared-styles";

.DateList {
  &.Root {
    margin-left: 10px;
  }

  &.Canceled {
    color: rgba(0, 0, 0, 0.3);
  }

  &.CurrentMonth {
    margin: 30px 10px;
    font-size: 20px;
  }

  &.Today {
    border: solid 1px $BLUE;
  }

  &.Dot {
    height: 10px;
    width: 10px;
    background-color: $BLUE;
    border-radius: 50%;
    display: inline-block;
  }

  &.DateDivider {
    display: flex;
    align-items: center;
  }
}
