@import "../../../assets/styles/vars";

.MapMarkerPin {
  position: relative;

  &-data {
    position: absolute;
    top: 0;
    left: 50%;
    min-width: 6rem;
    z-index: 1;
    transform: translate(-50%, -90%);
    background-color: $WHITE;
    padding: 0.25rem 0.5rem;
    border-radius: 3px;
    box-shadow: 0 0 5px 0 $GREY_300;
    text-align: center;

    &-route {
      width: 15px;
      height: 15px;
      background-color: $GREY_400;
      color: $WHITE;
      position: absolute;
      left: 0.25em;
      top: 0.25em;
      z-index: 1;
      font-size: 0.6em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }

  &-icon {
    width: 30px;
    position: relative;
    z-index: 2;
  }

  &-link {
    display: block;
    font-size: 0.75rem;
  }
}
.Clickable {
  cursor: pointer;
}
