@import "../../../assets/styles/vars";
@import "../../../assets/styles/shared-styles";

.MyPools {
  text-align: center;
  padding: 0.5em 1.5em;
  margin: 0 auto;
  max-width: 400px;
  height: 100%;

  .NoPools {
    line-height: 1.7;
  }
}

.PoolItemContainer {
  border-radius: 8px;
  border: solid 1px #c9d1d7;
  margin-bottom: 17px;
  min-height: 105px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15px 0px;
  cursor: pointer;

  .PoolName {
    text-align: center;
  }

  .StopInfo {
    margin-bottom: -15px;
  }
}

.SimpleStops {
  margin: 1rem 0;
  color: $GREY_600;
  display: grid;
  grid-template-columns: calc(50% - 2.5rem) 5rem calc(50% - 2.5rem);
  grid-template-rows: auto;
  flex-wrap: wrap;
  font-size: 13px;
  align-items: center;
  justify-items: center;

  .DoubleArrow {
    position: relative;
    height: 2px;
    width: 70%;
    background-color: $GREY_300;
  }

  .DoubleArrow::after {
    position: absolute;
    top: -3px;
    right: 0;
    content: " ";
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid $GREY_300;
  }

  .DoubleArrow::before {
    position: absolute;
    top: -3px;
    left: 0;
    content: " ";
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-right: 4px solid $GREY_300;
  }
}
