//@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;0,400;0,500;0,700;0,800;0,900;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,900;1,300;1,400;1,500;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow&display=swap');

%regular-font {
  font-family: Roboto;
  font-style: normal;
  line-height: 1.3;
}

%headings-font {
  font-family: Roboto;
  font-weight: normal;
  font-style: normal;
  line-height: 1.3;
}
