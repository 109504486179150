@import "../../../assets/styles/vars";
@import "../../../assets/styles/shared-styles";

.ManageVanpoolInvite {
  display: flex;
  flex-direction: column;
  row-gap: 23px;
  justify-content: center;
  align-items: center;

  .InfoText {
    text-align: center;
    max-width: 280px;
  }
  .InviteUrlBlock {
    display: flex;
    width: 100%;
  }
}

.UserList {
  overflow: auto;
  margin: 0px -18px;

  .UserItem {
    text-align: left;
    display: flex;
    align-items: center;
    height: 39px;
    margin: 0px 28px 13px 28px;
    justify-content: space-between;
  }

  .UserDisplay {
    display: flex;
    gap: 7px;
  }
}
