@import "../../../../assets/styles/vars";

.DataList {
  position: relative;
  margin: 0.5em 0;
  padding-bottom: 0.5em;

  &-icon {
    position: absolute;
    top: 2.5rem;
    left: 1.25rem;
  }

  &-input {
    display: block;
    width: 100%;
    position: relative;
    z-index: 1;
    box-sizing: border-box;

    &:focus,
    &.focused {
      border: 1px solid $BLUE;
      color: $BLUE;
    }
  }

  &-icon + &-input {
    padding-left: 2.5rem;
  }

  &-label {
    display: block;
    font-size: 0.8em;
    margin-bottom: 0.5em;
  }

  &-dropdown {
    position: absolute;
    top: calc(100% - 0.5rem);
    z-index: 3;
    width: calc(100%);
    overflow: hidden;
    display: block;
    border: 1px solid $GREY_300;
    border-top: none;
    border-radius: 0 0 3px 3px;
    background-color: $WHITE;

    &:empty {
      border: none;
    }

    &-backdrop {
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 1;
      left: 0;
      top: 0;
    }

    &-item {
      position: relative;
      padding: 0.75em 1.5em;
      font-size: 0.85rem;
      color: $GREY_400;
      cursor: pointer;

      & + & {
        &:before {
          content: "";
          width: 100%;
          border-top: 1px solid $GREY_200;
          height: 0;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &.empty {
        text-align: center;
      }

      &-name {
        font-weight: bold;
        color: $GREY_500;
        margin-right: 1em;
      }

      &:hover &-name {
        color: $BLUE;
      }
    }
  }

  &-input:focus ~ &-dropdown {
    height: auto;
  }
}
