@import "../../../assets/styles/vars";

.HelpModal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 10000;

  &-Box {
    position: relative;
    display: block;
    top: calc(50% - 15rem);
    min-height: 5rem;
    width: 25rem;
    margin: auto;
    padding: 1rem;
    background-color: $WHITE;
  }

  &-Title {
    font-size: 1.5rem;
    color: $BLUE;
  }

  &-Body {
    padding: 1rem 3rem;
  }

  &-Label {
    font-size: 0.75rem;
    color: $GREY_700;
  }

  &-Field {
    width: 100%;
    display: block;
    font-family: Roboto;
  }

  &-Submit {
    position: relative;
    display: block;
    width: 100%;
    margin: 1rem 0;
    padding: 0.75rem 1.5rem;
    background-color: $ORANGE;
    border: none;
    border-radius: 0.25rem;
    line-height: 1.5rem;
    font-size: 1.125rem;
    color: $WHITE;
  }

  &-status {
    text-align: center;
    font-size: 1rem;
    color: $RED;
  }

  &-icon {
    display: block;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin: auto;

    &-close {
      cursor: pointer;
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 1em;
      right: 1em;
    }
  }
}
