@import "vars";
@import "fonts";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  min-height: 100%;
}

body {
  @extend %regular-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $WHITE;
  font-size: 16px;
  color: $BLACK;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;

  > div:nth-child(1) {
    height: 100%;
  }
}

a {
  color: $PRIMARY;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $BLUE;
  }
}

button.btn {
  background-color: transparent;
  border: none;
  padding: 1em 3em;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1em;

  &:disabled {
    background-color: $GREY_300 !important;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }
}
button.btn-primary {
  background-color: $PRIMARY;
  color: $WHITE;
  border-radius: 10px;

  &.empty {
    border-radius: 5px;
    border: 2px solid $PRIMARY;
    background-color: transparent;
    color: $PRIMARY;
  }

  &:hover {
    color: $WHITE;
  }
}

input.mb,
textarea.mb {
  border-radius: 3px;
  border: 1px solid $GREY_300;
  background-color: transparent;
  padding: 0.75em 1.5em;
  color: $GREY;

  &:disabled {
    background-color: $GREY;
    cursor: not-allowed;
  }

  &:focus,
  &:active {
    outline: none;
  }

  &:focus {
    border: 1px solid $BLUE;
    color: $BLUE_100;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @extend %headings-font;
}

ul {
  list-style-type: none;
}
