@import "../../../../assets/styles/vars";

.DayPicker {
  &-Label {
    margin: 0.5rem;
    font-size: 0.75rem;
    color: $GREY_700;
  }

  &-Frame {
    margin: 0.5rem 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &-Input {
    display: none;
  }

  &-Day {
    margin: 0.125rem;
    position: relative;
    display: inline-block;
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 0.8rem;
    font-weight: 500;
    border-radius: 50%;
    text-align: center;
    background-color: $GREY_200;
    cursor: pointer;
  }
  .DayPicker-Input:checked + .DayPicker-Day {
    color: $WHITE;
    background-color: $BLUE;
    border-color: $BLUE;
  }
}
