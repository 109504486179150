@import "../../assets/styles/vars";
@import "../../assets/styles/shared-styles";

.NotFoundPage {
  height: calc(100% - #{$headerHeight});
  background-image: url("../../assets/img/404_bg.jpeg");
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10vh 2em;
  text-align: center;

  &-text {
    color: $WHITE;

    & .status {
      font-size: 5em;
    }

    & .description {
      font-size: 1.25em;
    }
  }

  &-actionBtn {
    display: block;
    min-width: 300px;
    padding: .75em 3em;
    border-radius: 3px;
  }

  @media screen and (max-width: 767px){
    &-text {
      & .status {
        font-size: 3em;
      }

      & .description {
        font-size: 1em;
      }
    }
  }
}
