.StopList {
  &.StopRow {
    display: grid;
    grid-template-columns: 10% 90%;
    align-items: center;

    &.Arrival {
      margin-bottom: -30px;
    }
  }

  &.LeftRow {
    margin-top: 16.9px;
    height: 100%;
  }

  &.RightRow {
    display: grid;
  }

  &.Stop {
    margin-bottom: 30px;
  }

  &.Riders {
    &.RiderBlock {
      margin-top: -0.95rem;
      margin-bottom: 35px;
    }

    &.IndentBlock {
      width: 4px;
      height: 100%;
      background-color: #f1f1f2;
    }
  }

  &.BlueLine {
    width: 1px;
    height: 100%;
    margin: 2.2px 0px 2.2px 4.8px;
    border: solid 1px #8dc1ff;
  }

  &.BlueCircle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px #0076ff, 0 0 0 2px #fff;
    background-color: #fff;
  }
}
