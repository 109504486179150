@import "../../../assets/styles/vars";

.ErrorModal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.1);
  text-align: center;
  z-index: 10000;

  &-Box {
    position: relative;
    display: block;
    top: calc(50% - 4rem);
    min-height: 5rem;
    width: 20rem;
    margin:auto;
    padding: 0.5rem 0;
    background-color: $WHITE;
  }

  &-Title {
    padding-top: 1rem;
    font-size: 1.5rem;
  }

  &-Message {
    padding: 1rem 3rem;
  }

  &-Submit {
    position: relative;
    display: block;
    width: calc(100% - 2rem);
    margin: 1rem;
    padding: 0.75rem 1.5rem;
    background-color: $ORANGE;
    border: none;
    border-radius: 0.25rem;
    line-height: 1.5rem;
    font-size: 1.125rem;
    font-family: Oxygen;
    font-weight: 900;
    color: $WHITE;
  }
}
