@import "../../assets/styles/vars";
@import "../../assets/styles/shared-styles";

.App {
  height: 100%;
  overflow: hidden;

  &-Header {
    height: $headerHeight;
    max-height: $headerHeight;
  }

  &-SidebarLayout {
    height: calc(100% - #{$headerHeight});
    width: 100vw;
    display: grid;
    grid-template-columns: 410px auto;
    grid-template-rows: 60px auto;
    flex-wrap: wrap;

    & .Interface {
      min-width: 320px;
      overflow: auto;
    }

    & .Map {
      grid-column-start: 2;
      grid-row-start: 1;
      grid-row-end: 3;
      width: 100%;
      height: 100%;
    }
  }

  &-SimpleLayout {
    height: calc(100% - #{$headerHeight});
    display: grid;
    width: 100vw;
    grid-template-rows: max-content auto;

    .Navbar {
      width: 100%;
    }

    .Navbar-header {
      @media screen and (max-width: 576px) {
        margin: 0px 25px;
      }
      margin: 0 auto;
      max-width: 710px;
      padding: 0px;

      .Navbar-header-title {
        padding: 0;
        font-size: 19px;
        color: #0076ff;
      }
    }
  }

  & .Navbar {
    min-width: 320px;

    &-header {
      @extend %sidebar-header;

      &-title {
        padding-right: 1rem;
      }
    }

    &-back {
      width: 2rem;
      padding: 1rem 1rem 1rem 0rem;
      margin: 12px 0;
      cursor: pointer;
    }

    &-back:before {
      content: "";
      display: block;
      width: 12px;
      height: 2px;
      transform: translateX(2px) rotate(46deg);
      transform-origin: 0 100%;
      background-color: $BLUE;
      border-radius: 2px;
      transition: all 0.2s ease-out;
    }

    &-back:after {
      content: "";
      display: block;
      width: 12px;
      height: 2px;
      transform: translateX(2px) rotate(-46deg);
      transform-origin: 0 0;
      background-color: $BLUE;
      border-radius: 2px;
      transition: all 0.2s ease-out;
    }
    &-filler {
      width: 2rem;
    }
  }

  @media screen and (max-width: 959px) {
    &-SidebarLayout {
      height: calc(100vh - #{$headerHeight});
      grid-template-columns: 1fr;
      grid-template-rows: 60px auto auto;
    }

    & .Navbar {
      width: 100vw;
      grid-column-start: 1;
      grid-row-start: 1;
    }

    & .Top-Interface {
      width: 100vw;
      height: calc(65vh - 60px - #{$headerHeight});
      grid-column-start: 1;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    & .Interface {
      width: 100vw;
      height: calc(65vh - 60px - #{$headerHeight});
      grid-column-start: 1;
      grid-row-start: 2;
      grid-row-start: 3;
    }

    & .Map {
      width: 100vw;
      height: 35vh;
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 2;
      grid-row-end: 2;
    }

    .Top-Interface + .Map {
      height: 35vh;
      grid-row-start: 3;
      grid-row-end: 3;
    }
  }
}
