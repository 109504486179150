@import "../../../assets/styles/vars";

.StopInfo {
  font-size: 0.95em;

  &-timestamp {
    font-weight: 500;
  }

  &-stop {
    margin: 1rem 0;
    color: $GREY_600;
    display: grid;
    grid-template-columns: calc(50% - 2.5rem) 5rem calc(50% - 2.5rem);
    grid-template-rows: auto;
    flex-wrap: wrap;

    &-address {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 0.75rem;
    }

    &-spaceholder {
      font-size: 0.75rem;
      visibility: hidden;
    }
  }
}
