@import "../../assets/styles/vars";
@import "../../assets/styles/shared-styles";

.Profile {
  display: flex;
  padding: 0.5em 1.5em;
  width: 100%;
  margin: 2rem auto;
  margin-top: 0;
  justify-content: center;

  &-container {
    width: 100%;
    max-width: 400px;
    height: 130%;
  }

  &-header {
    @extend %sidebar-header;
    padding: 0;
    height: auto;
    align-items: baseline;
    margin-bottom: 20px;
  }

  &-sub-header {
    color: $BLUE;
    margin-bottom: 0;
  }

  &-form {
    margin-bottom: 3em;
  }

  &-tip {
    color: $GREY;
    font-size: 0.9em;
    margin-bottom: 1.2em;

    &.center {
      text-align: center;
    }
  }

  &-Logout {
    display: block;
    width: 100%;
    padding: 0.75em;
    margin: 1em auto 0.5em;
    color: $GREY;
  }
}

.Copy-Link {
  display: block;
  text-align: right;
  text-decoration: none;
  font-size: 0.75rem;
  color: $GREY_700;
}

.SmsVerification {
  &-Cancel {
    display: block;
    width: 100%;
    padding: 0.75em;
    margin: 1em auto 0.5em;
    color: $GREY;
  }
}
