@import "../../../assets/styles/vars";
@import "../../../assets/styles/shared-styles";

.MyPoolRideDetails {
  margin: 2rem auto;

  .NoRide {
    font-size: 14px;
    margin-top: 50px;
    color: #4a4a4a;
  }

  .Label {
    color: #4a4a4a;
    font-size: 12px;
  }

  &.DriverVehicleStops {
    text-align: left;

    &.Wrapper {
      &.Canceled {
        opacity: 0.5;
      }
    }

    &.HeaderBlock {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 10px 0;
    }
  }

  .Divider {
    width: 100%;
    margin: 0;
  }

  .AssignButton {
    margin: 0;
    text-align: left;
    border-radius: 8px;
    border: solid 1px #c9d1d7;
    padding: 17.5px;

    &.Canceled {
      cursor: default;
    }

    &.Active {
      cursor: pointer;
    }
  }

  .Notice {
    text-align: center;
    border: solid 1px #c9d1d7;
    padding: 29px 49px;
    border-radius: 8px;
    font-size: 14px;

    &.Canceled {
      opacity: 2;
    }
  }

  .CoordinatorActions {
    margin-top: 42px;
  }

  .ButtonContainer {
    display: grid;
    row-gap: 5px;
    margin-top: 15px;
  }
}

.RideDetailsHeader {
  text-align: center;

  &.Date {
    font-size: 19px;
  }

  &.Driving {
    margin: 0 auto;
    padding: 7px 12px 8px 14px;
    border-radius: 18.5px;
    border: solid 1px #c8c8c8;
    max-height: 31px;
    font-weight: 500;
    color: #0076ff;
    max-width: fit-content;
  }
}

.RiderCount {
  margin-left: auto;
  grid-template-columns: 50% 50%;
  border-radius: 18.5px;
  border: solid 1px #c8c8c8;
  min-width: 50px;
  max-height: 31px;
  padding: 6px 2px 6px 2px;
  display: flex;
  justify-content: center;
  font-size: 14px;

  &.Overbooked {
    border: solid 1px rgba(255, 0, 0, 0.33);
    color: #ff0000;
  }
}

.RiderBlock {
  font-size: 14px;
  color: #4a4a4a;

  .RiderList {
    .SingleRider {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;

      .HighlightedRider {
        color: #0076ff;
      }
    }
  }
}
