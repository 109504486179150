@import "../../../assets/styles/vars";

.Trip {
  margin: 1rem 0;
  color: $GREY_600;
  display: grid;
  grid-template-columns: calc(50% - 2.5rem) 5rem calc(50% - 2.5rem);
  grid-template-rows: auto;
  flex-wrap: wrap;
  min-width: 320px;
  max-width: 440px;

  .Departure {
    text-align: right;
  }

  .Arrival {
    text-align: left;
  }

  &-duration {
    margin-top: -1px;
    padding: 0 1rem;
    color: $GREY_600;
    font-size: 0.7rem;
    text-align: center;
    line-height: 1.5rem;
  }

  &-arrow {
    margin-top: 4px;
    position: relative;
    height: 2px;
    width: 100%;
    background-color: $GREY_300;
  }
  &-arrow::after {
    position: absolute;
    top: -3px;
    right: -1px;
    content: " ";
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid $GREY_300;
  }
}
