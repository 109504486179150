.MyPoolRideSummary {
  .details {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 30px;
    align-items: center;

    .StopList {
      .RightRow {
        text-align: left;
      }
    }

    .header {
      font-size: 12px;
      text-align: left;
    }

    .text {
      font-size: 14px;
    }
  }
}
