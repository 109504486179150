@import "../../../assets/styles/vars";
@import "../../../assets/styles/shared-styles";

.AssignModal {
  &.ButtonStack {
    display: grid;
    row-gap: 12px;
  }

  &.ModalHeader {
    display: flex;
    justify-content: center;
    position: relative;

    &.HeaderText {
      color: #0076ff;
    }

    &.RiderCount {
      position: absolute;
      right: 0;
    }
  }

  &.Item {
    text-align: left;
    font-size: 14px;
    padding: 15px;
    cursor: pointer;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 7% 93%;
    align-items: center;

    &.Selected {
      outline: solid 1px #0076ff;

      &.Warn {
        outline: solid 1px #ff0000;
      }
    }
  }

  &.ItemWarning {
    color: #ff0000;
  }

  .SeatCapacity {
    width: 36px;
  }

  &.VehicleItem {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .UnselectedCircle {
    width: 15px;
    height: 15px;
    border-radius: 8px;
    outline: solid 1px #c9d1d7;
  }
}
