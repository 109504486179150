@import "../../../assets/styles/vars";

.ShareModal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.1);
  z-index: 10000;

  &-Box {
    position: relative;
    display: block;
    top: calc(50% - 4rem);
    min-height: 5rem;
    width: 25rem;
    margin:auto;
    padding: 1rem;
    background-color: $WHITE;
  }

  &-Title {
    font-size: 1.5rem;
    color: $BLUE;
  }

  &-Body {
    padding: 1rem 3rem;
  }

  &-Submit {
    position: relative;
    display: block;
    width: calc(100% - 2rem);
    margin: 1rem;
    padding: 0.75rem 1.5rem;
    background-color: $ORANGE;
    border: none;
    border-radius: 0.25rem;
    line-height: 1.5rem;
    font-size: 1.125rem;
    font-family: Oxygen;
    font-weight: 900;
    color: $WHITE;
  }

  &-shareIcons {
    display: flex;
  }

  &-badge {
    display: inline;
    width: 200px;
    margin: 1rem;
    text-align: center;
  }

  &-icon {
    display: block;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin: auto;

    &-close {
      cursor: pointer;
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 1em;
      right: 1em;
    }
  }
  &-sharelink {
    display: flex;
    border: solid;
    border-width: 1px;
    border-color: $GREY_200;
    border-radius: 5px;
    padding: 1rem;

    &-input {
      display: inline;
      border-width: 0;
      padding: 0;
    }

    &-input:focus {
      border:none;
    }

    &-button {
      display: inline;
      padding: 0;
      padding-left: 1rem;
      color: $BLUE;
    }
  }
}
